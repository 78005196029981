import Form from "./form";

import React, { useEffect, useState } from "react";
import { AdsService } from "../../services";
import { IAds } from "../../services/ads.service";
import { RiAddLine } from "react-icons/ri";
import { produce } from "immer";

export const Ads = () => {
  const [showModal, setShowModal] = useState(false);
  const [ads, setAds] = useState<IAds[]>([]);
  const [paging, setPaging] = useState<any>({ limit: 1000, skip: 0 });
  const [refetch, setRefetch] = useState(false);

  const onCheckboxChange = (index: number, item: IAds) => {
    AdsService.updateAds({ id: item.id, isActive: !item.isActive }).then((response) => {
      const produceAds = produce(ads, (draft) => {
        draft[index].isActive = !draft[index].isActive;
      })

      setAds(() => {
        return produceAds;
      })
    })
  }

  useEffect(() => {
    AdsService.getAds({
      limit: paging.limit,
      skip: paging.skip,
    }).then((response) => {
      setAds(response.data.data);
    })
  }, [paging, refetch])

  return (
    <>
      <div className="grid grid-flow-row auto-rows-max">
        <div>Ads List</div>
        <div>
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left">
              <thead className="text-xs text-white uppercase bg-gray-700">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    Ads Name
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Image
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Created Date
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {ads.map((item, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? 'bg-gray-200' : ''
                      }`}
                  >
                    <th scope="row" className="py-4 px-6 whitespace-nowrap">
                      {item.name}
                      <br />
                      {item.description}
                    </th>
                    <td className="py-4 px-6">
                      <img src={item.urlImage} className="w-20" />
                    </td>
                    <td className="w-45">
                      {item.createdAt.toString()}
                    </td>
                    <td className="py-4 px-6 w-10">
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={item.isActive} onChange={() => onCheckboxChange(index, item)} />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <button title="Create Ads"
        onClick={() => setShowModal(true)}
        className="fixed z-90 bottom-10 right-8 bg-gray-600 w-15 h-15 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-gray-700 hover:drop-shadow-2xl hover:animate-bounce duration-300">
        <RiAddLine />
      </button>
      {showModal ? (
        <>
          <div className="shadow flex justify-center items-center bg-gray-800 bg-opacity-30 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mt-[-20em] mx-auto w-10/12">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">General Info</h3>
                </div>
                <Form
                  navigateBack={() => { setShowModal(false); }}
                  onSuccess={() => { setRefetch(!refetch); }}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
