import { Navigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { getToken, logOut } from "../../services/auth.service";

export interface AuthGuardProps {
  children?: any;
}

const AuthGuard = (props: AuthGuardProps) => {
  const provider = localStorage.getItem("provider") as string;
  const { children } = props;
  const { address, isConnected } = useAccount({})
  if (provider == "metamask") {
    if (!isConnected) {
      logOut(localStorage.getItem("currentAddress") as string)
      return <Navigate to="/login" />;
    }
    const token = getToken(address as string);
    if (!token) {
      return <Navigate to="/login" />;
    }
  } else {
    const token = getToken();
    if (!token) {
      logOut(localStorage.getItem("currentAddress") as string)
      return <Navigate to="/login" />;
    }
  }
  return children;
};

export default AuthGuard;
