import { useEffect, useState } from "react";
import { AdsService, StorageService } from "../../services";
import InputField from "../../libs/FieldInput";

type FormProps = {
  navigateBack?: () => void;
  onSuccess?: () => void;
}

export default function Form(props: FormProps) {
  const { navigateBack, onSuccess } = props;

  const [selectedFile, setSelectedFile] = useState<Blob | MediaSource>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [preview, setPreview] = useState<string>()
  const [name, setName] = useState<string>()

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = (event: any) => {
    event.preventDefault();

    StorageService.uploadFile(selectedFile as File, "ads").then((response) => {
      const url = response.data.data;

      AdsService.createAds({
        name: name || "",
        description: '',
        urlImage: url,
      }).then((response) => {
        onSuccess?.();
        navigateBack?.();
      })
    })
  };

  useEffect(() => {
    if (!selectedFile) return

    // create the preview
    const objectUrl = URL.createObjectURL(selectedFile as Blob)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const removeImage = () => {
    setSelectedFile(undefined)
    setIsFilePicked(false)
  }

  return (
    <>
      <div>
        <div className="md:grid">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form onSubmit={handleSubmission}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <InputField
                    label="Ads Name"
                    name="name"
                    type="text"
                    value=""
                    onChange={(e: any) => { setName(e.target.value) }}
                    errors={[]}
                  />
                </div>
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Banner image                     {selectedFile && "(Click image to remove)"}</label>
                    {selectedFile &&
                      <div>
                        <img className="mx-auto mw-[80%] max-h-[300px]" src={preview} onClick={() => removeImage()} alt="preview" />
                      </div>
                    }
                    {!selectedFile &&
                      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                            >
                              <span>Upload a file</span>
                              <input id="file-upload" name="file-upload" type="file" accept="image/*" className="sr-only" onChange={changeHandler} />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => navigateBack?.()}
                  >
                    Close
                  </button>
                  <button
                    disabled={!isFilePicked || !name}
                    type="submit"
                    className="disabled:opacity-40 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
