import http from "../utils/http-common";

export interface RequestChallengeResponse {
  status_code: string;
  data: string;
}

export const requestChallenge = (address: string) => {
  return http.post<RequestChallengeResponse>("/api/account/auth/challenge", { address });
}

export interface Token {
  token: string;
  refreshToken: string;
}

export interface VerifyChallengeResponse {
  status_code: string;
  data: Token
}

export const verifyChallenge = (address: string, signature: string) => {
  return http.post<VerifyChallengeResponse>("/api/account/auth/authorize", { address, signature });
}

export const loginUsernamePassword = (username: string, password: string) => {
  return http.post<VerifyChallengeResponse>("/api/account/user-password/login-with-username-password", { username, password });
}

export const storeToken = (address: string, token: Token, provider: string = "metamask") => {
  localStorage.setItem("currentAddress", address as string)
  localStorage.setItem("provider", provider as string)
  localStorage.setItem(address as string, JSON.stringify(token))
}

export const getToken = (address?: string): Token => {
  if (!address) {
    address = localStorage.getItem("currentAddress") as string
  }

  return JSON.parse(localStorage.getItem(address) as string)
}

export const logOut = (address?: string) => {
  if (!address) {
    address = localStorage.getItem("currentAddress") as string
  }

  localStorage.removeItem(address)
}
