import http from "../utils/http-common";
import { getToken } from "./auth.service";

export interface CreateAdsResponse {
  status_code: string;
  data: string;
}

export const createAds = (data: {
  name: string,
  description: string,
  urlImage: string,
}) => {
  return http.post<CreateAdsResponse>("/api/ads/create", data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken().token}`
    },
  });
}

export interface IAds {
  id: string,
  name: string,
  description: string,
  urlImage: string,
  isActive: boolean,
  createdAt: Date,
}

export interface GetAdsResponse {
  status_code: string;
  data: IAds[]
}

export const getAds = (req: { limit: number, skip: number }) => {
  return http.get<GetAdsResponse>(`/api/ads/list?skip=${req.skip}&limit=${req.limit}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken().token}`
    },
  });
}

export interface UpdateAdsResponse {
  status_code: string;
  data: any;
}

export const updateAds = (data: {
  id: string,
  isActive: boolean,
}) => {
  return http.patch<UpdateAdsResponse>("/api/ads/edit", data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken().token}`
    },
  });
}

