import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { BsArrowLeftCircle } from 'react-icons/bs'
import { AiOutlineLogout } from 'react-icons/ai'
import { RiAdvertisementLine, RiHomeLine, RiGamepadLine } from 'react-icons/ri'

import { useAccount } from 'wagmi'
import { logOut } from '../../services/auth.service'

export const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const location = useLocation()

  const { address } = useAccount({})

  const Menus = [
    // { title: 'Home', path: '/', src: <RiHomeLine /> },
    // { title: 'Game', path: '/games', src: <RiGamepadLine /> },
    { title: 'Ads', path: '/', src: <RiAdvertisementLine /> },
    { title: 'Sign out', path: '/login', src: <AiOutlineLogout />, gap: 'true', onClick: () => { logOut() } },
  ]

  return (
    <>
      <div
        className={`${open ? 'w-60' : 'w-fit'
          } hidden sm:block relative h-screen duration-300 bg-gray-100 border-r border-gray-200 dark:border-gray-600 p-5 dark:bg-slate-800 h-screen sticky top-0`}
      >
        <BsArrowLeftCircle
          className={`${!open && 'rotate-180'
            } absolute text-3xl bg-white fill-slate-800  rounded-full cursor-pointer top-9 -right-4 dark:fill-gray-400 dark:bg-gray-800`}
          onClick={() => setOpen(!open)}
        />
        <Link to='/'>
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
            <img src="/images/wb-logo-v3.png" alt="worlds beyond" width="50px" height="50px" className="mx-auto h-[28px] w-[28px] sm:h-[32px] sm:w-[32px] md:h-[50px] md:w-[50px]" data-v-0830f15b="" />
            {open && (
              <span className='text-xl font-medium whitespace-nowrap dark:text-white'>
                Worlds Beyond
              </span>
            )}
          </div>
        </Link>

        <ul className='pt-6'>
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index} onClick={menu.onClick}>
              <li
                className={`flex items-center gap-x-6 p-3 text-base font-normal rounded-lg cursor-pointer dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700
                        ${menu.gap ? 'mt-9' : 'mt-2'} ${location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                  }`}
              >
                <span className='text-2xl text-white'>{menu.src}</span>
                <span
                  className={`${!open && 'hidden'
                    } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </>
  )
}
