import { WagmiConfig, createClient, configureChains, defaultChains } from 'wagmi'
import { Login } from './components/login'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { AuthGuard } from './components/authguard';
import { Main } from './components/main';
import { Ads } from './components/ads';
import { ListGame } from './components/game';

const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
  publicProvider(),
])

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
  ],
  provider,
  webSocketProvider,
})

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <AuthGuard><Main /></AuthGuard>,
    // errorElement: <Error404 />,
    children: [
      // {
      //   index: true,
      //   element: <div>Home</div>,
      // },
      {
        index: true,
        // path: "ads",
        element: <Ads />,
      },
      // {
      //   index: true,
      //   path: "games",
      //   element: <ListGame />,
      // },
    ],
  },
]);

export default function App() {
  console.log(process.env)
  return (
    <WagmiConfig client={client}>
      <RouterProvider router={router} />
    </WagmiConfig>
  )
}
