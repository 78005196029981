import { useEffect } from 'react'
import { logOut } from '../../services/auth.service'
import { LoginMetamask } from './login_metamask'
import { LoginUsernamePassword } from './login_usernamepassword'

export function Login() {
  useEffect(() => {
    logOut()
  }, [])
  return (
    <section className="min-h-screen flex flex-col">
      <div className="flex flex-1 items-center justify-center">
        <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
          <div className="text-center">
            <img src="/images/wb-logo-v3.png" alt="worlds beyond" width="75px" height="75px" className="mx-auto h-[28px] w-[28px] sm:h-[32px] sm:w-[32px] md:h-[75px] md:w-[75px]" data-v-0830f15b="" />
            <h1 className="font-bold tracking-wider text-3xl mb-8 w-full text-gray-600">
              Sign in
            </h1>
            <LoginUsernamePassword />
            <div
              className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p
                className="mx-4 mb-0 text-center font-semibold dark:text-neutral-500">
                OR
              </p>
            </div>
            <LoginMetamask />
          </div>
        </div>
      </div>
    </section>
  )
}
