import http from "../utils/http-common";
import { getToken } from "./auth.service";

export interface UploadFileResponse {
  status_code: string;
  data: string;
}

export const uploadFile = (file: File, uploadType: string) => {
  return http.post<UploadFileResponse>(`/api/storage/upload-file?uploadType=${uploadType}`, file, {
    headers: {
      'Content-Type': 'image/png',
      'Authorization': `Bearer ${getToken().token}`
    },
  });
}
