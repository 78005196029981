import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import { Sidebar } from "./sidebar";

export function Main() {
  return (
    <>
      <div className='flex flex-auto'>
        <Sidebar />
        <div className='grow'>
          <Navbar />
          <div className='m-5'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
