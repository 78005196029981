import { Connector, useAccount, useConnect, useSignMessage } from 'wagmi'
import { getToken, requestChallenge, storeToken, verifyChallenge } from '../../services/auth.service'

export function LoginMetamask() {
  const { signMessage, isLoading, error } = useSignMessage({
    onSuccess(data: `0x${string}`) {
      verifyChallenge(address as string, data).then((res) => {
        storeToken(address as string, res.data.data)
        window.location.href = '/'
      })
    },
  })

  const { address, isConnected } = useAccount({})

  const { connect, connectors } = useConnect({
    onSuccess: (address) => {
      if (getToken(address.account as string)) return

      requestChallenge(address.account as string).then((response) => {
        signMessage({ message: response.data.data })
      })
    },
  })

  if (isConnected && getToken(address as string)) {
    // redirect to /
    window.location.href = '/'
  }

  const login = (connector: Connector) => {
    if (!isConnected) {
      connect({ connector })
      return
    }

    requestChallenge(address as string).then((response) => {
      signMessage({ message: response.data.data })
    })
  }

  return (
    <div className="py-2">
      {connectors.map((connector) => (
        <button
          disabled={!connector.ready}
          key={connector.id}
          onClick={() => login(connector)}
          className="border-2 border-orange-100 focus:outline-none bg-orange-400 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-orange-700 hover:bg-orange-500"
        >
          <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" alt={connector.name} className="inline-block w-4 h-4 mr-2" />
          {connector.name}
          {!connector.ready && ' (unsupported)'}
          {isLoading &&
            ' (connecting)'}
        </button>
      ))}

      {error && <div className="text-red-600">{error.message}</div>}
    </div>
  )
}
