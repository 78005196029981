import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type InputFieldProps = {
  label: string;
  name: string;
  type: string;
  value: any;
  onChange: any;
  errors: { [key: string]: any; };
  required?: boolean,
} & InputProps;

function InputField(props: InputFieldProps) {
  const { label, type, name, value, onChange, errors, required } = props;
  const errorMessage = errors[name]
  const labelClassError = errorMessage ? "text-red-700 dark:text-red-500" : "";
  return (
    <div>
      <label htmlFor={props.id || props.name} className={`block mb-2 text-sm font-medium ${labelClassError} `}>{label}</label>
      <input
        type={type}
        name={name}
        defaultValue={value}
        onChange={onChange}
        required={required}
        className={`border text-sm rounded-lg block w-full p-2.5 `}
      />
      {errorMessage ? (
        <p className="mt-2 text-xs text-red-600 dark:text-red-500">{errorMessage}</p>
      ) : null}
    </div>
  );
}

export default InputField;
