import React from 'react'
import { useAccount } from 'wagmi'

const Navbar = () => {
  const { address } = useAccount({})
  return (
    <nav className='border-gray-200 mx-2 px-2 py-2.5'>
      <div className='container flex'>
        <div className='flex'>
          <span className='text-xl font-medium whitespace-nowrap'>
            Logged address {address}
          </span>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
