import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { loginUsernamePassword, storeToken } from "../../services/auth.service";

type LoginInput = {
  username: string;
  password: string;
};

export function LoginUsernamePassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit } = useForm<LoginInput>();

  const onSubmit = handleSubmit((data: LoginInput) => {
    console.log(data)
    loginUsernamePassword(data.username, data.password).then((res) => {
      storeToken(data.username, res.data.data, "username")
      window.location.href = '/'
    }).catch((err) => {
      setError(err.response.data.message)
    })
  });
  return (
    <div className="py-2">
      <form className="mt-6" onSubmit={onSubmit}>
        <div className="mb-2">
          <label
            className="block text-sm font-semibold text-gray-800 text-left"
          >
            Username
          </label>
          <input
            type="text"
            autoComplete="email"
            className="block w-full px-4 py-2 mt-2 text-blue-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            {...register("username")}
          />
        </div>
        <div className="mb-2">
          <label
            className="block text-sm font-semibold text-gray-800 text-left"
          >
            Password
          </label>
          <input
            type="password"
            autoComplete="current-password"
            className="block w-full px-4 py-2 mt-2 text-blue-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
            {...register("password")}
          />
        </div>
        <div className="mt-6">
          <button disabled={loading} className="disabled:opacity-40 w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-700 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">
            Login
          </button>
        </div>
      </form>

      {error && <div className="text-red-600">{error}</div>}
    </div>
  )
}
